import React, { useEffect, useState, useContext } from 'react'
import Router, { useRouter } from 'next/router'
import AuthGlobal from '../../context/store/global'
import TopBar from '../TopBar'
import Footer from '../Footer'
import './index.css'
import Zoom from 'react-reveal/Zoom'
import Jump from 'react-reveal/Jump'
import ProgressBar from '../Progress/ProgressBar'
import { getScoreboardMetrics } from '../../utils/scoreboards-helper'
import Accordion from './accoridion'
import { quarter1Data, quarter2Data, quarter3Data, quarter4Data } from './accordionData'
import DonutChart from '../donutChart'
import Scoreboard from '../../components/Scoreboard'
import GenericScore from '../Scores/GenericScore'

const Dashboard = () => {
    const context = useContext(AuthGlobal)
    //GeneralMetrics it is equal to Total Compliace
    const [currentGeneralMetrics, setCurrentGeneralMetrics] = useState({})

    //RockMetrics it is equal to Main Activities
    const [currentRockMetrics, setCurrentRockMetrics] = useState({})

    //Personal Metrics it is equal to Genetal Activities
    const [currentPersonalMetrics, setCurrentPersonalMetrics] = useState({})

    const [revenue, setRevenue] = useState('')
    const [profit, setProfit] = useState('')

    const [totalScores, setTotalScores] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (!context.globalState.isAuthenticated) {
            Router.push(`/login`)
        }
    }, [context.globalState.isAuthenticated])

    useEffect(() => {
        async function getAllMetrics() {
            const { success, response, error } = await getScoreboardMetrics()
            if (success && response) {
                setCurrentGeneralMetrics(response.generalScores)
                setCurrentRockMetrics(response.rockScores)
                setCurrentPersonalMetrics(response.personalScores)
                setRevenue(response.revenue)
                setProfit(response.profit)
                setTotalScores(10)
                setLoading(false)
            }
        }
        getAllMetrics()
    }, [])

    const getTotalMetrics = scores => {
        const total =
            scores.lowCriticalNumber + scores.mediumCriticalNumber + scores.criticalNumber + scores.highCriticalNumber
        return total
    }

    return (
        <div className='main-wraper'>
            <TopBar></TopBar>
            <div className='main-content'>
                <section className='section'>
                    <div className='section-header'>
                        <img className='mr-2' width='120' height='60' src='images/theme/logo.png'></img>
                        <h1>ClickIT Culture</h1>
                    </div>
                    <div className='section-body'>
                        <div className='row equal dashboard-page'>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <div id='accordion'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingCoreValues'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapse'
                                                    aria-expanded='false'
                                                    aria-controls='collapseCoreValues'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/core_values.png'
                                                        ></img>
                                                        Core Values
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapse'
                                                className='collapse'
                                                aria-labelledby='headingCoreValues'
                                                data-parent='#accordion'
                                            >
                                                <div
                                                    className='card-body d-flex align-items-center'
                                                    style={{ height: '25rem' }}
                                                >
                                                    <div>
                                                        <p
                                                            style={{
                                                                marginTop: '12px',
                                                                display: 'flex',
                                                                alignItems: 'center'
                                                            }}
                                                        >
                                                            <img
                                                                src='images/core-values/Collaboration.png'
                                                                alt='Collaboration Icon'
                                                                className='icon'
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    marginRight: '8px'
                                                                }}
                                                            />
                                                            <b className='highlight-text'>Collaboration: </b>
                                                            &nbsp;&nbsp;&nbsp; Collaboration breeds innovation.
                                                        </p>
                                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img
                                                                src='images/core-values/Commitment.png'
                                                                alt='Commitment Icon'
                                                                className='icon'
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    marginRight: '8px'
                                                                }}
                                                            />
                                                            <b className='highlight-text'>Commitment: </b>
                                                            &nbsp;&nbsp;&nbsp; Commitment propels us towards success.
                                                        </p>
                                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img
                                                                src='images/core-values/Evolution.png'
                                                                alt='Evolution Icon'
                                                                className='icon'
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    marginRight: '8px'
                                                                }}
                                                            />
                                                            <b className='highlight-text'>Evolution: </b>
                                                            &nbsp;&nbsp;&nbsp; Never stop evolving; keep on learning.
                                                        </p>
                                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img
                                                                src='images/core-values/Diversity.png'
                                                                alt='Diversity Icon'
                                                                className='icon'
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    marginRight: '8px'
                                                                }}
                                                            />
                                                            <b className='highlight-text'>Diversity: </b>
                                                            &nbsp;&nbsp;&nbsp; Embracing diversity, fostering unity.
                                                        </p>
                                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                                            <img
                                                                src='images/core-values/Heartitude.png'
                                                                alt='Heartitude Icon'
                                                                className='icon'
                                                                style={{
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    marginRight: '8px'
                                                                }}
                                                            />
                                                            <b className='highlight-text'>Heartitude: </b>
                                                            &nbsp;&nbsp;&nbsp; Heartitude shines through every action.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <div id='accordion'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingPurpose'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapse'
                                                    aria-expanded='false'
                                                    aria-controls='collapsePurpose'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/purpose.png'
                                                        ></img>
                                                        Purpose
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapse'
                                                className='collapse'
                                                aria-labelledby='headingPurpose'
                                                data-parent='#accordion'
                                            >
                                                <div
                                                    className='card-body d-flex align-items-center'
                                                    style={{ height: '25rem' }}
                                                >
                                                    <div>
                                                        <p>
                                                            <i
                                                                className='fas fa-star pr-1'
                                                                style={{ color: '#FFC107' }}
                                                            ></i>
                                                            Our purpose is to transform the lives of both our clients
                                                            and collaborators. We achieve this by providing our clients
                                                            with quality solutions that overcome their expectations,
                                                            which in return will allow us to provide an ideal workplace
                                                            and conditions for every employee at ClickIT, ensuring their
                                                            professional, personal and economic development.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <div id='accordion'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingBrandPromises'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapse'
                                                    aria-expanded='false'
                                                    aria-controls='collapseBrandPromises'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/brand_promises.png'
                                                        ></img>
                                                        Brand Promises
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapse'
                                                className='collapse'
                                                aria-labelledby='headingBrandPromises'
                                                data-parent='#accordion'
                                            >
                                                <div
                                                    className='card-body d-flex align-items-center'
                                                    style={{ height: '25rem' }}
                                                >
                                                    <div>
                                                        <p>
                                                            <i
                                                                className='fas fa-star pr-1'
                                                                style={{ color: '#FFC107' }}
                                                            ></i>
                                                            Your goals become ours
                                                        </p>
                                                        <p>
                                                            <i
                                                                className='fas fa-star pr-1'
                                                                style={{ color: '#FFC107' }}
                                                            ></i>
                                                            Lean and agile support at the right time
                                                        </p>
                                                        <p>
                                                            <i
                                                                className='fas fa-star pr-1'
                                                                style={{ color: '#FFC107' }}
                                                            ></i>
                                                            Cost effective solutions
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                        {revenue === ''  || profit === '' ?  '' : 
                            <div>
                                <div className='section-header'>
                                    <img className='mr-2' width='120' height='60' src='images/theme/logo.png'></img>
                                    <h1>Critical Numbers</h1>
                                </div> 
                                <div className='row equal dashboard-page'>
                                    <div className='col col-12 col-md-12 col-xl-6'>
                                        <GenericScore scoreboard={profit}></GenericScore>
                                    </div>
                                    <div className='col col-12 col-md-12 col-xl-6'>
                                        <GenericScore scoreboard={revenue}></GenericScore>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='section-header'>
                            <img className='mr-2' width='120' height='60' src='images/theme/logo.png'></img>
                            <h1>Compliance Levels</h1>
                        </div>
                        <div className='row equal dashboard-page'>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <div
                                        className='card'
                                        style={
                                            {
                                                /* "minHeight": "373px" */
                                            }
                                        }
                                    >
                                        <div
                                            className='card-header d-flex align-items-center'
                                            style={{ background: '#054aaf' }}
                                        >
                                            <img
                                                className='mr-2'
                                                width='60'
                                                height='60'
                                                style={{
                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'
                                                }}
                                                src='images/theme/general_activities.png'
                                                alt='Team Logo'
                                            />
                                            <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                General Activities
                                                <h6 className='text-light' style={{ fontSize: '14px' }}>
                                                    Compliance:{' '}
                                                    {(
                                                        ((currentPersonalMetrics.highCriticalNumber +
                                                            currentPersonalMetrics.criticalNumber) /
                                                            getTotalMetrics(currentPersonalMetrics)) *
                                                        100
                                                    ).toFixed(2)}{' '}
                                                    %
                                                </h6>
                                            </h4>
                                        </div>
                                        <div className='card-body'>
                                            <form>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: 'green' }}>SuperGreen</label>
                                                        <ProgressBar
                                                            bgcolor={'green'}
                                                            showed={currentPersonalMetrics.highCriticalNumber}
                                                            completed={
                                                                (currentPersonalMetrics.highCriticalNumber * 100) /
                                                                getTotalMetrics(currentPersonalMetrics)
                                                            }
                                                            total={getTotalMetrics(currentPersonalMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: 'rgb(58, 208, 34)' }} className='mt-1'>
                                                            Green
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'rgb(58, 208, 34)'}
                                                            showed={currentPersonalMetrics.criticalNumber}
                                                            completed={
                                                                (currentPersonalMetrics.criticalNumber * 100) /
                                                                getTotalMetrics(currentPersonalMetrics)
                                                            }
                                                            total={getTotalMetrics(currentPersonalMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: '#FFC312' }} className='mt-1'>
                                                            Yellow
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'#FFC312'}
                                                            showed={currentPersonalMetrics.mediumCriticalNumber}
                                                            completed={
                                                                (currentPersonalMetrics.mediumCriticalNumber * 100) /
                                                                getTotalMetrics(currentPersonalMetrics)
                                                            }
                                                            total={getTotalMetrics(currentPersonalMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label className='mt-1' style={{ color: 'red' }}>
                                                            Red
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'red'}
                                                            showed={currentPersonalMetrics.lowCriticalNumber}
                                                            completed={
                                                                (currentPersonalMetrics.lowCriticalNumber * 100) /
                                                                getTotalMetrics(currentPersonalMetrics)
                                                            }
                                                            total={getTotalMetrics(currentPersonalMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                            </form>
                                        </div>
                                        {!loading && (
                                            <DonutChart
                                                superGreen={currentPersonalMetrics.highCriticalNumber}
                                                green={currentPersonalMetrics.criticalNumber}
                                                yellow={currentPersonalMetrics.mediumCriticalNumber}
                                                red={currentPersonalMetrics.lowCriticalNumber}
                                                style={{ marginBottom: '15px', display: 'none' }}
                                            ></DonutChart>
                                        )}
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <div
                                        className='card'
                                        style={
                                            {
                                                /* "minHeight": "373px" */
                                            }
                                        }
                                    >
                                        <div className='card-header bg-dark d-flex align-items-center'>
                                            <img
                                                className='mr-2'
                                                width='60'
                                                height='60'
                                                style={{
                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'
                                                }}
                                                src='images/theme/objectives.png'
                                                alt='Pikachu'
                                            />
                                            <div>
                                                <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                    Objectives
                                                </h4>
                                                <h6 className='text-light' style={{ fontSize: '14px' }}>
                                                    Compliance:{' '}
                                                    {(
                                                        ((currentRockMetrics.highCriticalNumber +
                                                            currentRockMetrics.criticalNumber) /
                                                            getTotalMetrics(currentRockMetrics)) *
                                                        100
                                                    ).toFixed(2)}{' '}
                                                    %
                                                </h6>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <form>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: 'green' }}>SuperGreen</label>
                                                        <ProgressBar
                                                            bgcolor={'green'}
                                                            showed={currentRockMetrics.highCriticalNumber}
                                                            completed={
                                                                (currentRockMetrics.highCriticalNumber * 100) /
                                                                getTotalMetrics(currentRockMetrics)
                                                            }
                                                            total={getTotalMetrics(currentRockMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: 'rgb(58, 208, 34)' }} className='mt-1'>
                                                            Green
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'rgb(58, 208, 34)'}
                                                            showed={currentRockMetrics.criticalNumber}
                                                            completed={
                                                                (currentRockMetrics.criticalNumber * 100) /
                                                                getTotalMetrics(currentRockMetrics)
                                                            }
                                                            total={getTotalMetrics(currentRockMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: '#FFC312' }} className='mt-1'>
                                                            Yellow
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'#FFC312'}
                                                            showed={currentRockMetrics.mediumCriticalNumber}
                                                            completed={
                                                                (currentRockMetrics.mediumCriticalNumber * 100) /
                                                                getTotalMetrics(currentRockMetrics)
                                                            }
                                                            total={getTotalMetrics(currentRockMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label className='mt-1' style={{ color: 'red' }}>
                                                            Red
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'red'}
                                                            showed={currentRockMetrics.lowCriticalNumber}
                                                            completed={
                                                                (currentRockMetrics.lowCriticalNumber * 100) /
                                                                getTotalMetrics(currentRockMetrics)
                                                            }
                                                            total={getTotalMetrics(currentRockMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                            </form>
                                        </div>
                                        {!loading && (
                                            <DonutChart
                                                superGreen={currentRockMetrics.highCriticalNumber}
                                                green={currentRockMetrics.criticalNumber}
                                                yellow={currentRockMetrics.mediumCriticalNumber}
                                                red={currentRockMetrics.lowCriticalNumber}
                                                style={{ marginBottom: '15px', display: 'none' }}
                                            ></DonutChart>
                                        )}
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-4'>
                                <Zoom>
                                    <div
                                        className='card'
                                        style={
                                            {
                                                /* "minHeight": "373px" */
                                            }
                                        }
                                    >
                                        <div className='card-header bg-dark d-flex align-items-center'>
                                            <img
                                                className='mr-2'
                                                width='60'
                                                height='60'
                                                style={{
                                                    position: 'relative',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)'
                                                }}
                                                src='images/theme/total_compliance.png'
                                                alt='Coins'
                                            />
                                            <div>
                                                <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                    Total Compliance
                                                </h4>
                                                <h6 className='text-light' style={{ fontSize: '14px' }}>
                                                    Compliance:{' '}
                                                    {(
                                                        ((currentGeneralMetrics.highCriticalNumber +
                                                            currentGeneralMetrics.criticalNumber) /
                                                            getTotalMetrics(currentGeneralMetrics)) *
                                                        100
                                                    ).toFixed(2)}{' '}
                                                    %
                                                </h6>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <form>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: 'green' }}>SuperGreen</label>
                                                        <ProgressBar
                                                            bgcolor={'green'}
                                                            showed={currentGeneralMetrics.highCriticalNumber}
                                                            completed={
                                                                (currentGeneralMetrics.highCriticalNumber * 100) /
                                                                getTotalMetrics(currentGeneralMetrics)
                                                            }
                                                            total={getTotalMetrics(currentGeneralMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: 'rgb(58, 208, 34)' }} className='mt-1'>
                                                            Green
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'rgb(58, 208, 34)'}
                                                            showed={currentGeneralMetrics.criticalNumber}
                                                            completed={
                                                                (currentGeneralMetrics.criticalNumber * 100) /
                                                                getTotalMetrics(currentGeneralMetrics)
                                                            }
                                                            total={getTotalMetrics(currentGeneralMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label style={{ color: '#FFC312' }} className='mt-1'>
                                                            Yellow
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'#FFC312'}
                                                            showed={currentGeneralMetrics.mediumCriticalNumber}
                                                            completed={
                                                                (currentGeneralMetrics.mediumCriticalNumber * 100) /
                                                                getTotalMetrics(currentGeneralMetrics)
                                                            }
                                                            total={getTotalMetrics(currentGeneralMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                                <Jump>
                                                    <div className='form-row'>
                                                        <label className='mt-1' style={{ color: 'red' }}>
                                                            Red
                                                        </label>
                                                        <ProgressBar
                                                            bgcolor={'red'}
                                                            showed={currentGeneralMetrics.lowCriticalNumber}
                                                            completed={
                                                                (currentGeneralMetrics.lowCriticalNumber * 100) /
                                                                getTotalMetrics(currentGeneralMetrics)
                                                            }
                                                            total={getTotalMetrics(currentGeneralMetrics)}
                                                        />
                                                    </div>
                                                </Jump>
                                            </form>
                                        </div>
                                        {!loading && (
                                            <DonutChart
                                                superGreen={currentGeneralMetrics.highCriticalNumber}
                                                green={currentGeneralMetrics.criticalNumber}
                                                yellow={currentGeneralMetrics.mediumCriticalNumber}
                                                red={currentGeneralMetrics.lowCriticalNumber}
                                                style={{ marginBottom: '15px', display: 'none' }}
                                            ></DonutChart>
                                        )}
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                    <Zoom>
                        <div className='section-header'>
                            <img className='mr-2' width='120' height='60' src='images/theme/logo.png'></img>
                            <h1>Strategic Priorities</h1>
                        </div>
                    </Zoom>
                    <div className='section-body'>
                        <div className='row'>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <div id='accordionFirstQuarter'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingOne'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapseOne'
                                                    aria-expanded='false'
                                                    aria-controls='collapseOne'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/quarter_1.png'
                                                        ></img>
                                                        First Quarter
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapseOne'
                                                className='collapse'
                                                aria-labelledby='headingOne'
                                                data-parent='#accordionFirstQuarter'
                                            >
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col col-12' id='firstQuarterAccordion'>
                                                            {quarter1Data.map((firstQItem, index) => (
                                                                <Accordion
                                                                    title={firstQItem.title}
                                                                    data={firstQItem.data}
                                                                    keyId={index}
                                                                    key={index}
                                                                    parentId='firstQuarterAccordion'
                                                                ></Accordion>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <div id='accordionSecondQuarter'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingTwo'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapseTwo'
                                                    aria-expanded='false'
                                                    aria-controls='collapseTwo'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/quarter_2.png'
                                                        ></img>
                                                        Second Quarter
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapseTwo'
                                                className='collapse'
                                                aria-labelledby='headingTwo'
                                                data-parent='#accordionSecondQuarter'
                                            >
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col col-12' id='secondQuarterAccordion'>
                                                            {quarter2Data.map((firstQItem, index) => (
                                                                <Accordion
                                                                    title={firstQItem.title}
                                                                    data={firstQItem.data}
                                                                    keyId={index}
                                                                    key={index}
                                                                    parentId='secondQuarterAccordion'
                                                                ></Accordion>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <div id='accordionThirdQuarter'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingThree'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapseThree'
                                                    aria-expanded='false'
                                                    aria-controls='collapseThree'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/quarter_3.png'
                                                        ></img>
                                                        Third Quarter
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapseThree'
                                                className='collapse'
                                                data-target='#collapseThree'
                                                aria-labelledby='headingThree'
                                                data-parent='#accordionThirdQuarter'
                                            >
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col col-12' id='thirdQuarterAccordion'>
                                                            {quarter3Data.map((firstQItem, index) => (
                                                                <Accordion
                                                                    title={firstQItem.title}
                                                                    data={firstQItem.data}
                                                                    keyId={index}
                                                                    key={index}
                                                                    parentId='thirdQuarterAccordion'
                                                                ></Accordion>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-12 col-md-12 col-xl-6'>
                                <Zoom>
                                    <div id='accordionFourthQuarter'>
                                        <div className='card'>
                                            <div className='card-header bg-dark' id='headingFour'>
                                                <a
                                                    data-toggle='collapse'
                                                    style={{ width: '100%' }}
                                                    data-target='#collapseFour'
                                                    aria-expanded='false'
                                                    aria-controls='collapseFour'
                                                >
                                                    <h4
                                                        className='text-light'
                                                        style={{
                                                            fontSize: '20px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <img
                                                            className='mr-2'
                                                            width='60'
                                                            height='60'
                                                            src='images/theme/quarter-4.png'
                                                        ></img>
                                                        Fourth Quarter
                                                        <i className={`fas fa-caret-down ml-auto`}></i>
                                                    </h4>
                                                </a>
                                            </div>
                                            <div
                                                id='collapseFour'
                                                className='collapse'
                                                aria-labelledby='headingFour'
                                                data-parent='#accordionFourthQuarter'
                                            >
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col col-12' id='fourthQuarterAccordion'>
                                                            {quarter4Data.map((firstQItem, index) => (
                                                                <Accordion
                                                                    title={firstQItem.title}
                                                                    data={firstQItem.data}
                                                                    keyId={index}
                                                                    key={index}
                                                                    parentId='fourthQuarterAccordion'
                                                                ></Accordion>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                        <div className='section-header'>
                            <img className='mr-2' width='120' height='60' src='images/theme/logo.png'></img>
                            <h1>Goals</h1>
                        </div>
                        <div className='row equal dashboard-page'>
                            <div className='col col-6 col-md-6'>
                                <Zoom>
                                    <div className='card'>
                                        <div className='card-header bg-dark'>
                                            <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                <img
                                                    className='mr-2'
                                                    width='50'
                                                    height='60'
                                                    src='images/theme/quarter_goal.png'
                                                ></img>
                                                Quarter Goal
                                            </h4>
                                        </div>
                                        <div className='card-body align-items-center d-flex p-2'>
                                            <div>
                                                <p>
                                                    <i className='fas fa-star pr-1' style={{ color: '#FFC107' }}></i>
                                                    By the end of March 2024, the total revenue of the quarter must be a
                                                    profit of 8%, revenue of 8% and operational cost savings of 3%. The
                                                    priorities being more sales and creation of more opportunities in
                                                    the sales pipeline.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-6 col-md-6'>
                                <Zoom>
                                    <div className='card'>
                                        <div className='card-header bg-dark'>
                                            <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                <img
                                                    className='mr-2'
                                                    width='60'
                                                    height='60'
                                                    src='images/theme/year_goal.png'
                                                ></img>
                                                Current Year Goal
                                            </h4>
                                        </div>
                                        <div className='card-body align-items-center d-flex p-2'>
                                            <div>
                                                <p>
                                                    <i className='fas fa-star pr-1' style={{ color: '#FFC107' }}></i>
                                                    By the end of 2024, the company&#39;s growth should be +40% compared to
                                                    the previous year, with an average profit of 8.7% per quarter,
                                                    focusing on more sales and attention to the customer satisfaction
                                                    process.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-6 col-md-6'>
                                <Zoom>
                                    <div className='card'>
                                        <div className='card-header bg-dark'>
                                            <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                <img
                                                    className='mr-2'
                                                    width='60'
                                                    height='60'
                                                    src='images/theme/years.png'
                                                ></img>
                                                Three - Five Years
                                            </h4>
                                        </div>
                                        <div className='card-body align-items-center'>
                                            <div>
                                                <p>
                                                    <i className='fas fa-star pr-1' style={{ color: '#FFC107' }}></i>By
                                                    the year 2026, the total revenue must be:{' '}
                                                    <strong>$4.5 Million</strong> USD, and have over{' '}
                                                    <strong>100</strong> collaborators, <strong>80</strong> engineers in
                                                    operations, and <strong>20</strong> in administration
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col col-6 col-md-6'>
                                <Zoom>
                                    <div
                                        className='card'
                                        style={
                                            {
                                                /* "minHeight": "373px" */
                                            }
                                        }
                                    >
                                        <div className='card-header bg-dark'>
                                            <h4 className='text-light' style={{ fontSize: '20px' }}>
                                                <img
                                                    className='mr-2'
                                                    width='60'
                                                    height='60'
                                                    src='images/theme/bhag.png'
                                                ></img>
                                                BHAG - 10 years
                                            </h4>
                                        </div>
                                        <div className='card-body align-items-center d-flex p-2'>
                                            <div>
                                                <p>
                                                    <i className='fas fa-star pr-1' style={{ color: '#FFC107' }}></i>In
                                                    the year 2031, ClickIT DevOps & Software Development is located in
                                                    the top 5 of the Mexican IT agencies with wide international
                                                    recognition, and is made up of more than 600 employees.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default Dashboard
