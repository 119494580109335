/*
Month      getMonth()  quarter
---------  ----------  -------
January         0         1
February        1         1
March           2         1
April           3         2
May             4         2
June            5         2
July            6         3
August          7         3
September       8         3
October         9         4
November       10         4
December       11         4
*/

let _month
let _quarter

export function getQuarter() {
    _month = new Date().getMonth()
    _quarter = Math.floor((_month + 3) / 3)
    return _quarter
}

export function getQuarterToString(_quarter) {
    switch (_quarter) {
    case 1:
        return 'Quarter 1'
    case 2:
        return 'Quarter 2'
    case 3:
        return 'Quarter 3'
    case 4:
        return 'Quarter 4'
    default:
        return 'Quarter 0'
    }
}

export function getDeadLine(_quarter) {
    switch (_quarter) {
    case 1:
        return new Date().getFullYear() + '-03-31'
    case 2:
        return new Date().getFullYear() + '-06-30'
    case 3:
        return new Date().getFullYear() + '-09-30'
    case 4:
        return new Date().getFullYear() + '-12-31'
    default:
        return '1970-01-01'
    }
}

export function getDeadLineDateFormat(_quarter) {
    switch (_quarter) {
    case 1:
        return new Date(new Date().getFullYear(), 2, 31) // El mes se establece en 2 (marzo) para representar el 31 de marzo
    case 2:
        return new Date(new Date().getFullYear(), 5, 30) // El mes se establece en 5 (junio) para representar el 30 de junio
    case 3:
        return new Date(new Date().getFullYear(), 8, 30) // El mes se establece en 8 (septiembre) para representar el 30 de septiembre
    case 4:
        return new Date(new Date().getFullYear(), 11, 31) // El mes se establece en 11 (diciembre) para representar el 31 de diciembre
    default:
        return new Date(1970, 0, 1) // Fecha por defecto en caso de valor no válido
    }
}

export function getRegistrationDeadline() {
    const currentQuarter = getQuarter()
    let deadlineDate

    switch (currentQuarter) {
    case 1:
        // Si es el primer trimestre, la fecha límite será la del cuarto caso más 15 días, tendran hasta 15 de enero para registrar
        deadlineDate = new Date(getDeadLine(4))
        break
    case 2:
        // Si es el segundo trimestre, la fecha límite será la del primer caso más 15 días, tendran hasta 15 de abril para registrar
        deadlineDate = new Date(getDeadLine(1))
        break
    case 3:
        // Si es el tercer trimestre, la fecha límite será la del segundo caso más 15 días, tendran hasta 15 de julio para registrar
        deadlineDate = new Date(getDeadLine(2))
        break
    case 4:
        // Si es el cuarto trimestre, la fecha límite será la del tercer caso más 15 días, tendran hasta 15 de octubre para registrar
        deadlineDate = new Date(getDeadLine(3))
        break
    default:
        deadlineDate = new Date(1970, 0, 1) // Fecha por defecto en caso de valor no válido
    }

    // Sumar 15 días a la fecha límite obtenida
    deadlineDate.setDate(deadlineDate.getDate() + 16)

    return deadlineDate
}

export function isRegistrationAllowed() {
    const currentDate = new Date()
    const deadlineDate = getRegistrationDeadline()
    return currentDate.getTime() <= deadlineDate.getTime()
}
