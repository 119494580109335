import React, { useState, useEffect, useContext } from 'react'
import Router from 'next/router'
import SideBar from '../Sidebar'
import './topbar.css'
import AuthGlobal from '../../context/store/global'
import { logoutUser } from '../../context/actions'
import { accessUser } from '../../context/actions'

const TopBar = () => {
    const context = useContext(AuthGlobal)

    window.onload = function () {
        var sessionStart = Date.now()
        var timer = setInterval(function () {
            if (
                (Date.now() - sessionStart > 3600000 &&
                    !window.location.pathname.includes('/login') &&
                    !window.location.pathname.includes('/forgotpassword') &&
                    context.globalState.isAuthenticated) ||
                (Date.now() - sessionStart > 3600000 &&
                    window.location.pathname.includes('/') &&
                    context.globalState.isAuthenticated)
            ) {
                logoutUser(context.dispatch)
                clearTimeout(timer)
                alert('Session time out. Please log in again')
                window.location.href = `/login`
            }
        }, 3600000)
    }

    return (
        <>
            <div className='navbar-bg'></div>
            <nav className='navbar  main-navbar'>
                <form className='form-inline mr-auto d-xl-none'>
                    <ul className='navbar-nav mr-3'>
                        <li>
                            <a data-toggle='sidebar' className='nav-link nav-link-lg text-white'>
                                <i className='fas fa-bars'></i>
                            </a>
                        </li>
                    </ul>
                    <div className='search-element'></div>
                </form>
                <ul className='navbar-nav navbar-right ml-auto'>
                    {context.globalState.isAuthenticated ? (
                        <li className='dropdown'>
                            <a
                                href='#'
                                data-toggle='dropdown'
                                className='nav-link dropdown-toggle nav-link-lg nav-link-user'
                            >
                                <img
                                    alt='image'
                                    src={
                                        context.globalState.user.photo
                                            ? context.globalState.user.photo
                                            : '../images/profile-bone.jpg'
                                    }
                                    width='100%'
                                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                    className='rounded-circle mr-1'
                                />
                                <div className='d-sm-none d-lg-inline-block'>
                                    Hi, {context.globalState.user.first_name} {context.globalState.user.last_name}
                                </div>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                                <div className='dropdown-title'>Welcome!</div>
                                <div className='dropdown-divider'></div>
                                <a href='/profile' className='dropdown-item has-icon'>
                                    <img src='/images/theme/profile.png' width='40' className='mr-2'></img> My Profile
                                </a>
                                <div className='dropdown-divider'></div>
                                <a
                                    onClick={e => {
                                        logoutUser(context.dispatch)
                                        Router.push('/')
                                    }}
                                    className='dropdown-item has-icon text-danger'
                                >
                                    <img src='/images/theme/logout.png' width='40' className='mr-2'></img> Logout
                                </a>
                            </div>
                        </li>
                    ) : (
                        <li>
                            <a href='/login' className='nav-link  nav-link-user'>
                                <div className=''>Login</div>
                            </a>
                        </li>
                    )}
                </ul>
            </nav>
            <SideBar />
        </>
    )
}

export default TopBar
