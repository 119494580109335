import { fetchRequest } from '.';

export const getScoreboards = (data,department) => {
    return fetchRequest(`/department/${department}/scoreboards?` + new URLSearchParams(data), {
        method: 'GET',
    });   
};

export const register = (scoreboard) => {
    return fetchRequest(`/scoreboard/register`, {
        method: 'POST',
        body: JSON.stringify(scoreboard),
    });
};

export const getMyScoreboards = (data) => {
    return fetchRequest(`/scoreboard/assigned?` + new URLSearchParams(data), {
        method: 'GET',
    });   
}


export const updateScoreboard = (scoreboardSlug, data) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/update`, {
        method: "PUT",
        body: JSON.stringify(data),
    });
}

export const hideScoreboard = (scoreboardSlug, data) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/hide`,{
        method:'PATCH',
        body: JSON.stringify(data)
    })
}

export const priorityScoreboard = (scoreboardSlug, data) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/priority`,{
        method:'PATCH',
        body: JSON.stringify(data)
    })
}
export const deleteScoreboard = (scoreboardSlug) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/delete`,{
        method:'DELETE'
    })
}

export const getScoreboardNotes =  (scoreboardSlug) => {
    return fetchRequest(`/scoreboard/${scoreboardSlug}/notes`,{
        method:"GET"
    })
}

export const getScoreboardMetrics =  () => {
    return fetchRequest(`/scoreboard/getmetrics`,{
        method:"GET"
    })
}