import React from 'react';
import './footer.css';

const Footer = () => {
    return(
        <footer className="main-footer">
            <div className="footer-left">
                Copyright &copy; 2020
            </div>
            <div className="footer-right">
                v1.0.0
            </div>
        </footer>
    )
}

export default Footer;